import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, AIMLButtonIcon } from '../../../components';
import Fields from "./FormFields.json";
import { incidentActions, getDepartments, getSeverityTypes, getActiveUsers, getDepartmentResponders, getDeptResponderByDept, commonActions } from "../../../store";
import { useNavigate } from "react-router-dom";
import {CreateWithEvalButton} from "./AIMLButton";

const ResponderActions = [
  { label: 'Not Now', variant: 'lite', className: 'min gap', color: '#0133CC' },
  { label: 'Confirm', },
]

export const NewIncidentModal = (props) => {
  const { open } = props;
  const _ref = React.useRef(null)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const departments = useSelector(getDepartments);
  const severities = useSelector(getSeverityTypes);
  const orgUsers = useSelector(getActiveUsers);
  const deptResponders = useSelector(getDepartmentResponders);
  const [state, setState] = React.useState({ incident: { department: null, responder: null } });
  const [responderPopup, setResponderPopup] = useState({
    showResponderPopup: false,
    isResponderManuallySet: false,
    department: '',
    responder: '',
  })
  const [showAIMLPopup, setShowAIMLPopup] = useState(false)
  const [showAIMLDropdownButton, setShowAIMLDropdownButton] = useState(false)
  const [errors, setErrors] = useState({});
  const incidentFields = Fields.Incident

  React.useEffect(() => {
    if (!open) {
      setState((_) => ({ ..._, incident: {} }))
      setErrors({}); // Reset errors when modal is closed
    }
  }, [open])

  const validateFields = () => {
    const { incident } = state;
    const newErrors = {};
    if (!incident.title || incident.title.trim() === "") {
      newErrors.title = "Title is required.";
    }
    if (!incident.department || !incident.department.id) {
      newErrors.department = "Department is required.";
    }
    if (!incident.severity || !incident.severity.id) {
      newErrors.severity = "Severity is required.";
    }
    if (!incident.summary || incident.summary.trim() === "") {
      newErrors.summary = "Summary is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmitNewIncident = (e, useAI) => {
    e.preventDefault && e.preventDefault();
    const isFormValid = validateFields();
    if (!isFormValid) {
      return; // Stop if validation fails
    }
    if (useAI) {
      setShowAIMLPopup(true)
    }
    const body = new FormData();
    incidentFields.forEach(({ type, attribute }) => {
      let value = state.incident[attribute], key;
      if (attribute === 'documents') {
        if (value && value.length > 0) {
          value.forEach((file, i) => {
            body.append(`${attribute}[evidence_${i + 1}]`, file);
          })
        }
      } else {
        if (type === 'select') {
          value = (value && value.id) ? value.id : value;
        }
        key = attribute === 'responder' ? 'responder[ent_usr_id]' : `incident[${attribute}]`
        body.append(key, value)
      }
    });
    // for (let [key, value] of body.entries()) {
    //   console.log(`Key: ${key}, Value: ${value}`);
    // }
    const navigateToDetailsPage = (targetLink) => {
      if (targetLink) {
        navigate(targetLink)
      }
    }
    if (useAI) {
      setTimeout(() => {
        setShowAIMLPopup(false)
        dispatch(incidentActions.createIncident({ body, useAI: true, navigateToDetailsPage }));
        props.onClose && props.onClose();
      }, 5000)
    }
    else {
      dispatch(incidentActions.createIncident({ body, useAI: false, navigateToDetailsPage }));
      props.onClose && props.onClose();
    }

  }
  const handleCancel = () => {
    setShowAIMLDropdownButton(false)
    props.onClose && props.onClose();
  }
  const Actions = [
    { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC' },
    <CreateWithEvalButton
      onCreate={handleSubmitNewIncident}
      showAIMLPopup={showAIMLPopup}
      setShowAIMLPopup={setShowAIMLPopup}
      showAIMLDropdownButton={showAIMLDropdownButton}
      setShowAIMLDropdownButton={setShowAIMLDropdownButton}
    />
  ]
  const getActions = () => {
    return Actions.map((_, i) => {
      let action = { ..._ };
      action.onClick = (i == 0) ? handleCancel : handleSubmitNewIncident
      return action
    });
  }
  const getResponderPopupActions = () => {
    return ResponderActions.map((_, i) => {
      let action = { ..._ };
      action.onClick = (i == 0) ? handleResponderPopUpClose : handleResponderPopUpConfirm
      return action
    });
  }
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case 'department': options = departments; break;
      case 'severity':
        options = [];
        severities.forEach((_) => {
          if (String(_.id).toLowerCase() !== 'none') {
            options.push({ ..._, className: `severity ${String(_.id).toLowerCase()}` })
          }
        })
        break;
      case 'responder':
        options = orgUsers.map((_) => {
          return { label: `${_._listname}, ${_.designation}, ${_.department_name}`, id: _.id }
        })
        break;
      default: options = []; break;
    }
    return options
  }

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const popupdata = { ...responderPopup }
    const incident = { ...state.incident }
    incident[name] = value;

    if (value && name === 'department') {
      let deptResp = getDeptResponderByDept(deptResponders, value.id);
      if (deptResp.responder) {
        incident.responder = { label: deptResp.responder_listname, id: deptResp.responder.id }
      } else {
        incident.responder = null
        setResponderPopup((_) => ({ ..._, isResponderManuallySet: true }))
      }
    }
    if (value && name === 'responder' && (responderPopup.isResponderManuallySet)) {
      popupdata.showResponderPopup = true
      popupdata.department = incident.department
      popupdata.responder = incident.responder
      setResponderPopup(popupdata);
    }

    setState((_) => ({ ..._, incident: incident }));
  };

  const handleResponderPopUpClose = () => {
    setResponderPopup({
      showResponderPopup: false,
      isResponderManuallySet: false,
      department: '',
      responder: '',
    })
  }
  const handleResponderPopUpConfirm = () => {
    const body = {
      department: responderPopup.department.id,
      userId: responderPopup.responder.id
    }
    dispatch(commonActions.updateDeptResponder(body));
    setResponderPopup({
      showResponderPopup: false,
      isResponderManuallySet: false,
      department: '',
      responder: '',
    })
  }
  const handleDeleteFile = (attribute, index) => {
    const incident = state.incident;
    if (Array.isArray(incident[attribute]) && incident[attribute].length > 0) {
      incident[attribute].splice(index, 1)
    }
    setState((_) => ({ ..._, incident: { ...incident } }))
  }
  return (
    <>
      <Modal
        width={580}
        open={open}
        className="new-inc-modal"
        footerWrapperClassName="footer-wrapper"
        actions={getActions()}
        onCancel={props.onClose}
        title={<span className="exo2 f24 c238787">New Incident</span>}>
        {
          open && (
            <>
              <Form
                ref={_ref}
                onSubmit={handleSubmitNewIncident}
                className="form row fwarp h-btn"
                formData={{ ...state.incident }}
                Fields={incidentFields}
                getOptions={getOptions}
                onChange={handleValueChange}
                onDeleteFile={handleDeleteFile}
                errors={errors}
                setErrors={setErrors}
              />
            </>
          )}
      </Modal>
      {
        responderPopup.showResponderPopup &&
        <Modal
          width={650}
          className='responder-popup'
          open={responderPopup.showResponderPopup}
          actions={getResponderPopupActions()}
          onCancel={handleResponderPopUpClose}
          title={<span className="exo2 f24 c238787">Update Responder Permanently?</span>}
        >
          <div className="f18 mt2">
            <div>Incident Responder for {responderPopup.department && responderPopup.department.label} is NOT defined.<br />Confirm whether Incident Responder list should be updated as well.</div>
            <div className="mt2">
              <div>Department: <b>{responderPopup.department && responderPopup.department.label || ''}</b></div>
              <div>Responder: <b>{responderPopup.responder && responderPopup.responder.label || ''}</b></div>
            </div>
            <hr className="hr-styled" />
          </div>
        </Modal>
      }
    </>
  )
}