import React from 'react';
import { Modal as AntModal } from 'antd';
import { Button } from "../Button";
import "./style.scss";

const ModalFooter = (props) => {
  const { footeClassName, actions, CustomFooter } = props;
  return (
    <div className={props.footerWrapperClassName}>
      {Boolean(CustomFooter) ?
        <CustomFooter />
        : Array.isArray(actions) && actions.length > 0 ?
          <div className={`row ${footeClassName || 'h-end'}`}>
            {
              actions.map((action, i) => {
                // Check if the action is a React component (like <AIMLButtonIcon />) or an object
                if (React.isValidElement(action)) {
                  return <React.Fragment key={i}>{action}</React.Fragment>;
                } else if (typeof action === 'object') {
                  return <Button {...action} key={i} />;
                }
                return null;
              })
            }
          </div>
          : null
      }
    </div>
  )
}

export const Modal = (props) => {
  const { open, onClose, title, closeIcon, className, width } = props;
  return (
    <AntModal
      rootClassName='neu-modal'
      className={`${className || ''}`} width={width || 100} footer={<ModalFooter {...props} />}
      open={open} title={title} onCancel={onClose} closeIcon={closeIcon || null}>
      {props.children}
    </AntModal>
  )
}

export const TransparentDialog = (props) => {
  return (
    <Modal open className={`transparent-dialog ${props.className || ''}`}>
      <div className='dialog-container'>
        {props.children}
      </div>
    </Modal>
  )
}