import React, { useState } from "react";
import { ProfilePic, CreateWithoutEvalButton, AIMLButtonGIF, WarningOutlinedIcon, AIMLPopup, AIMLPopupGIF } from "../../../components";

// Custom Button with Icon and Loading Wrapper
export const CreateWithEvalButton = ({ onCreate, showAIMLPopup, showAIMLDropdownButton, setShowAIMLDropdownButton }) => {

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowAIMLDropdownButton(!showAIMLDropdownButton);
  };
  const handleCreateWithEval = (e) => {
    e.stopPropagation();
    onCreate(e, true); // Call the create function with AI
  };

  return (
    <div className="modal-content">
      <div className="button-wrapper">
        {/* <AIMLButtonGIF className="gif-loader" /> */}
        <div className="button-container">
          <button className="custom-ai-button">
            <div onClick={handleCreateWithEval} className="row">
              <span className="label exo2">Create with Eval</span>
              <span className="icon">
                <ProfilePic />
              </span>
            </div>
            <div className="divider-dropdown-wrapper" onClick={toggleDropdown}>
              <span className="divider"></span>
              <span className="dropdown-arrow">▼</span>
            </div>
          </button>
          {showAIMLDropdownButton && (
            <CreateWithoutEvalButton
              className="dropdown-menu dropdown-item"
              onClick={(e) => { onCreate(e, false) }}
            />
          )}
        </div>
      </div>
      <div className="warning-container">
        <WarningOutlinedIcon className='warning-outlined' />
        <span className="warning-text">AI-generated content!<br />Verify accuracy before using.</span>
      </div>
      {showAIMLPopup && (
        <div className="popup-overlay" style={{ opacity: '0.8' }}>
          <div className="popup-wrapper">
            <AIMLPopupGIF className="popup-gif-loader" />
            <div className="popup-content" style={{ opacity: '1 !important' }}>
              <AIMLPopup className="aiml-popup" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const UpdateWithEvalButton = ({handleUpdateWithEval}) => {
  return (
    <div>
      <button className="custom-ai-button update-eval-btn">
        <div onClick={handleUpdateWithEval} className="row">
          <span className="label exo2">Update with Eval</span>
          <span className="icon">
            <ProfilePic />
          </span>
        </div>
      </button>
    </div>
  )
}