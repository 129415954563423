import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../app";

const initialState = {
  incidents: null,
  viewIncident: null,
  totalIncidents: 0,
  deptResponders: [],
  severityTypes: [],
  statusTypes: [],
  priorityTypes: [],
  dateRangeEnumns: [],
  statistics: { severity: [], department: [] },
  aiIncidentDetails: null// ai
}

const incidentSlice = createSlice({
  name: "incident",
  initialState: initialState,
  reducers: {
    fetchSeverityTypes: () => { },
    fetchStatusTypes: () => { },
    fetchPriorities: () => { },
    fetchDateRangeEnums: () => { },
    fetchIncidents: () => { },
    createIncident: () => { },
    fetchIncidentDetailsFromAI: () => { },
    fetchIncident: () => { },
    fetchDeptStats: () => { },
    fetchSeverityStats: () => { },
    updateIncidentResponder: () => { },
    removeIncidentResponder: () => { },
    addIncidentComment: () => { },
    updateIncident: () => { },
    createIncidentTask: () => { },
    downloadIncidentReport: () => { },
    shareIncidentReport: () => { },
    exportSeverityStats: () => { },
    exportDeptStats: () => { },
    setIncidents: (state, action) => {
      state.incidents = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalIncidents = action.payload
    },
    setSeverityTypes: (state, action) => {
      state.severityTypes = action.payload
    },
    setStatusTypes: (state, action) => {
      state.statusTypes = action.payload
    },
    setProrities: (state, action) => {
      state.priorityTypes = action.payload
    },
    setDateRangeEnums: (state, action) => {
      state.dateRangeEnumns = action.payload
    },
    setIncident: (state, action) => {
      const { incident } = action.payload;
      if (incident && !Array.isArray(state.incidents)) {
        state.incidents = [incident];
      }
      state.viewIncident = incident;
    },
    setStatistics: (state, action) => {
      const { department, severity } = action.payload;
      if (department) {
        state.statistics = { ...state.statistics, department: department }
      }
      if (severity) {
        state.statistics = { ...state.statistics, severity: severity }
      }
    },
    setAIIncidentDetails: (state, action) => {
      state.aiIncidentDetails = action.payload
    }
  }
})
export const incidentActions = incidentSlice.actions;

ReducerRegistry.register("incident", incidentSlice.reducer)